/**
 * CommentList Component
 * 
 * Renders a list of comments for a proposal discussion.
 * This component serves as a container/wrapper that maps through
 * the comments array and renders individual Comment components.
 * 
 * @param {Object} props
 * @param {Array} props.comments - Array of comment objects to be rendered
 * @param {string} props.userWalletAddress - The current user's wallet address,
 *                                          passed down to individual Comment components
 * 
 * @returns {JSX.Element} A div containing the list of Comment components
 */

import React from 'react';
import Comment from './Comment';

const CommentList = ({ comments, userWalletAddress }) => {
  return (
    <div className="comments-list">
      {comments.map(comment => (
        <Comment 
          key={comment.id}
          comment={comment}
          userWalletAddress={userWalletAddress}
        />
      ))}
    </div>
  );
};

export default CommentList; 