import React, { useState } from 'react';
import './SortDropdown.css';

const SortDropdown = ({ onSort, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    { value: 'date_desc', label: 'Newest' },
    { value: 'date_asc', label: 'Oldest' },
    { value: 'price_desc', label: 'Price High' },
    { value: 'price_asc', label: 'Price Low' },
    { value: 'name_asc', label: 'Name (A-Z)' },
    { value: 'name_desc', label: 'Name (Z-A)' }
  ];

  const handleSort = (value) => {
    onSort(value);
    setIsOpen(false);
  };

  return (
    <div className={`sort-dropdown ${className || ''}`}>
      <button 
        className="sort-dropdown__trigger"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>Sort</span>
        <svg 
          className={`sort-dropdown__arrow ${isOpen ? 'open' : ''}`}
          width="10" 
          height="6" 
          viewBox="0 0 10 6" 
          fill="none"
        >
          <path d="M1 1L5 5L9 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
      </button>
      {isOpen && (
        <div className="sort-dropdown__menu">
          {options.map((option) => (
            <button
              key={option.value}
              className="sort-dropdown__option"
              onClick={() => handleSort(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortDropdown; 