import React, { useState } from 'react';
import './SortDropdown.css';

const TabsDropdown = ({ activeTab, onTabChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    { value: 'transactions', label: 'Transactions' },
    { value: 'tokens', label: 'Tokens' },
    { value: 'nfts', label: 'NFTs' },
    { value: 'hooks', label: 'Hooks' }
  ];

  const handleSelect = (value) => {
    onTabChange(value);
    setIsOpen(false);
  };

  return (
    <div className="sort-dropdown left-aligned">
      <button 
        className="sort-dropdown__trigger"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>Tabs</span>
        <svg 
          className={`sort-dropdown__arrow ${isOpen ? 'open' : ''}`}
          width="10" 
          height="6" 
          viewBox="0 0 10 6" 
          fill="none"
        >
          <path d="M1 1L5 5L9 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
      </button>
      {isOpen && (
        <div className="sort-dropdown__menu">
          {options.map((option) => (
            <button
              key={option.value}
              className="sort-dropdown__option"
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TabsDropdown; 