/**
 * CollectionsGallery Component
 * 
 * A gallery display component that showcases NFT collections on the platform.
 * Currently using mock data for development purposes.
 * 
 * Features:
 * - Displays a responsive grid of collection cards
 * - Each card shows:
 *   • Collection banner image
 *   • Collection name
 *   • Creator information
 *   • Description
 *   • Key statistics (Floor Price, Total Items, Volume Traded)
 * 
 * Navigation:
 * - Clicking a collection card navigates to /collections/{collectionId}
 * 
 * Data Source:
 * - Currently uses generateRandomCollections() from mockData utility
 * - Generates 20 mock collections on component mount
 * - Data is memoized to prevent unnecessary regeneration
 * 
 * @component
 * @example
 * return (
 *   <CollectionsGallery />
 * )
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateRandomCollections } from '../utils/mockData';
import './CollectionsGallery.css';

const CollectionsGallery = () => {
  const navigate = useNavigate();
  const mockCollections = React.useMemo(() => generateRandomCollections(20), []);

  return (
    <div className="collections-gallery">
      <div className="collections-grid">
        {mockCollections.map((collection) => (
          <div 
            key={collection.id} 
            className="collection-card"
            onClick={() => navigate(`/collections/${collection.id}`)}
          >
            <div className="card-banner">
              <img src={collection.bannerImage} alt={collection.name} />
            </div>
            <div className="card-info">
              <h2>{collection.name}</h2>
              <p className="creator">by {collection.creator}</p>
              <p className="description">{collection.description}</p>
              <div className="card-stats">
                <div className="stat">
                  <span className="label">Floor Price</span>
                  <span className="value">{collection.floorPrice}</span>
                </div>
                <div className="stat">
                  <span className="label">Total Items</span>
                  <span className="value">{collection.totalItems}</span>
                </div>
                <div className="stat">
                  <span className="label">Volume Traded</span>
                  <span className="value">{collection.volumeTraded}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollectionsGallery; 