import React, { useState } from 'react';
import './CommunityGrants.css';
import GrantCard from '../components/GrantCard';
import SortDropdown from '../components/SortDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faVoteYea, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

// Mock data
const mockGrants = [
  {
    id: 1,
    title: "DeFi Analytics Dashboard",
    status: "Open for Voting",
    amount: "50,000",
    timeline: "3 months",
    category: "Development",
    image: "https://picsum.photos/400/200?random=1"
  },
  {
    id: 2,
    title: "Community Education Program",
    status: "In Review",
    amount: "25,000",
    timeline: "6 months",
    category: "Education",
    image: "https://picsum.photos/400/200?random=2"
  },
  {
    id: 3,
    title: "Cross-Chain Bridge Development",
    status: "Open for Voting",
    amount: "75,000",
    timeline: "4 months",
    category: "Infrastructure",
    image: "https://picsum.photos/400/200?random=3"
  },
  {
    id: 4,
    title: "Mobile Wallet Integration",
    status: "Approved",
    amount: "45,000",
    timeline: "2 months",
    category: "Development",
    image: "https://picsum.photos/400/200?random=4"
  },
  {
    id: 5,
    title: "Security Audit Framework",
    status: "In Progress",
    amount: "30,000",
    timeline: "3 months",
    category: "Security",
    image: "https://picsum.photos/400/200?random=5"
  },
  {
    id: 6,
    title: "Developer Documentation Portal",
    status: "Open for Voting",
    amount: "20,000",
    timeline: "2 months",
    category: "Documentation",
    image: "https://picsum.photos/400/200?random=6"
  },
  {
    id: 7,
    title: "Community Governance Tool",
    status: "In Review",
    amount: "40,000",
    timeline: "3 months",
    category: "Governance",
    image: "https://picsum.photos/400/200?random=7"
  },
  {
    id: 8,
    title: "NFT Marketplace Enhancement",
    status: "Open for Voting",
    amount: "55,000",
    timeline: "4 months",
    category: "NFT",
    image: "https://picsum.photos/400/200?random=8"
  },
  {
    id: 9,
    title: "Smart Contract Templates",
    status: "Approved",
    amount: "35,000",
    timeline: "2 months",
    category: "Development",
    image: "https://picsum.photos/400/200?random=9"
  },
  {
    id: 10,
    title: "Community Events Platform",
    status: "In Progress",
    amount: "15,000",
    timeline: "1 month",
    category: "Community",
    image: "https://picsum.photos/400/200?random=10"
  },
  {
    id: 11,
    title: "Decentralized Identity Solution",
    status: "Open for Voting",
    amount: "60,000",
    timeline: "5 months",
    category: "Identity",
    image: "https://picsum.photos/400/200?random=11"
  },
  {
    id: 12,
    title: "Trading Bot Framework",
    status: "In Review",
    amount: "45,000",
    timeline: "3 months",
    category: "Trading",
    image: "https://picsum.photos/400/200?random=12"
  },
  {
    id: 13,
    title: "Ecosystem Analytics Dashboard",
    status: "Open for Voting",
    amount: "25,000",
    timeline: "2 months",
    category: "Analytics",
    image: "https://picsum.photos/400/200?random=13"
  },
  {
    id: 14,
    title: "Multi-Signature Wallet",
    status: "In Progress",
    amount: "50,000",
    timeline: "4 months",
    category: "Security",
    image: "https://picsum.photos/400/200?random=14"
  },
  {
    id: 15,
    title: "Layer 2 Scaling Solution",
    status: "Open for Voting",
    amount: "80,000",
    timeline: "6 months",
    category: "Infrastructure",
    image: "https://picsum.photos/400/200?random=15"
  }
];

const CommunityGrants = () => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeStatus, setActiveStatus] = useState(null);

  const categories = [
    'Development',
    'Education',
    'Infrastructure',
    'Security',
    'Documentation',
    'Governance',
    'Analytics',
    'Community',
    'Identity',
    'Trading',
    'NFT'
  ];

  const statuses = [
    { label: 'Open for Voting', icon: faVoteYea },
    { label: 'In Review', icon: faClock },
    { label: 'Approved', icon: faCheckCircle },
    { label: 'In Progress', icon: faSpinner }
  ];

  const handleSort = (value) => {
    console.log('Sort by:', value);
  };

  const resetFilters = () => {
    setActiveCategory(null);
    setActiveStatus(null);
  };

  const handleGrantClick = (grantId) => {
    navigate(`/grants/${grantId}`);
  };

  return (
    <div className="community-container">
      <div className="community-header">
        <h1>Community Grants</h1>
        <p>Connect, collaborate, and create with the community</p>
      </div>
      <div className="grants-section">
        <div className="grants-header">
          <div className="filter-section">
            <div className="filter-row">
              <div className="filter-group">
                {categories.map(category => (
                  <button
                    key={category}
                    className={`filter-button category ${category} ${activeCategory === category ? 'active' : ''}`}
                    onClick={() => setActiveCategory(activeCategory === category ? null : category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
              <button 
                className="reset-button"
                onClick={resetFilters}
                style={{ opacity: (activeCategory || activeStatus) ? 1 : 0.5 }}
              >
                Reset Filters
              </button>
            </div>
            <div className="filter-row statuses">
              {statuses.map(status => (
                <button
                  key={status.label}
                  className={`filter-button status ${status.label.toLowerCase().replace(/\s+/g, '-')} ${activeStatus === status.label ? 'active' : ''}`}
                  onClick={() => setActiveStatus(activeStatus === status.label ? null : status.label)}
                >
                  <FontAwesomeIcon icon={status.icon} className="status-icon" />
                  {status.label}
                </button>
              ))}
            </div>
          </div>
          <SortDropdown onSort={handleSort} />
        </div>
        <div className="grants-grid">
          {mockGrants.map(grant => (
            <GrantCard 
              key={grant.id} 
              grant={grant} 
              onClick={() => handleGrantClick(grant.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityGrants; 