/**
 * CommentInput Component
 * 
 * A form component that allows users to submit new comments on proposals.
 * Features include:
 * - Text area for comment input
 * - User avatar display
 * - Submit button
 * - Basic form validation (empty comment prevention)
 * 
 * @param {Object} props
 * @param {string} props.userWalletAddress - The current user's wallet address
 * @param {Function} props.onSubmit - Callback function that handles the comment submission
 *                                   Takes the comment text as a parameter
 * 
 * @returns {JSX.Element} A comment input form with avatar and submit button
 */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const CommentInput = ({ userWalletAddress, onSubmit }) => {
  const [commentText, setCommentText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!commentText.trim()) return;
    onSubmit(commentText);
    setCommentText('');
  };

  return (
    <div className="comment-input-container">
      <div className="user-avatar">
        <FontAwesomeIcon icon={faUser} />
      </div>
      <div className="comment-input-wrapper">
        <textarea
          className="comment-input"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder="Share your thoughts on this proposal..."
        />
        <button 
          className="comment-submit"
          onClick={handleSubmit}
        >
          Post Comment
        </button>
      </div>
    </div>
  );
};

export default CommentInput; 