import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import './MintModal.css';

const MintModal = ({ isOpen, onClose, mintPrice, maxMints = 10 }) => {
  const [mintCount, setMintCount] = useState(1);

  if (!isOpen) return null;

  const decrementCount = () => {
    if (mintCount > 1) setMintCount(mintCount - 1);
  };

  const incrementCount = () => {
    if (mintCount < maxMints) setMintCount(mintCount + 1);
  };

  const totalPrice = (mintPrice * mintCount).toFixed(2);

  return (
    <div className="mint-modal-overlay">
      <div className="mint-modal">
        <h2>Mint NFTs</h2>
        <div className="mint-content">
          <div className="mint-counter">
            <button 
              className="counter-btn" 
              onClick={decrementCount}
              disabled={mintCount <= 1}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <span className="mint-count">{mintCount}</span>
            <button 
              className="counter-btn" 
              onClick={incrementCount}
              disabled={mintCount >= maxMints}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          
          <div className="mint-price-info">
            <div className="price-row">
              <span>Price per NFT:</span>
              <span className="value">{mintPrice} XAH</span>
            </div>
            <div className="price-row total">
              <span>Total Price:</span>
              <span className="value">{totalPrice} XAH</span>
            </div>
          </div>

          <div className="mint-actions">
            <button className="mint-button" onClick={() => console.log('Minting:', mintCount)}>
              Mint Now
            </button>
            <button className="cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintModal; 