/**
 * Card Component
 * 
 * A reusable card component primarily used in the "What's New" section of the home page.
 * Supports both internal routing (using React Router) and external links.
 * 
 * Props:
 * @param {string} image - URL of the card's image
 * @param {string} title - Card title text
 * @param {string} description - Card description/content
 * @param {string} link - URL for card click (internal route or external URL)
 * @param {boolean} isExternal - If true, opens link in new tab (default: false)
 * @param {string} color - Border color of the card (default: '#61dafb')
 * @param {string} titleColor - Color of the title text (default: '#61dafb')
 * 
 * Usage Example:
 * <Card
 *   image="/path/to/image.jpg"
 *   title="New Feature"
 *   description="Check out our latest update"
 *   link="/features/new"
 *   color="#ff0000"
 * />
 */

import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const Card = ({ 
  image, 
  title, 
  description, 
  link, 
  isExternal = false,
  color = '#61dafb',
  titleColor = '#61dafb'  // New prop for title color
}) => {
  const CardWrapper = isExternal ? 'a' : Link;
  const cardProps = isExternal ? { 
    href: link, 
    target: "_blank", 
    rel: "noopener noreferrer" 
  } : { 
    to: link 
  };

  return (
    <CardWrapper {...cardProps} className="card" style={{ borderColor: color }}>
      <div className="card-image">
        <img src={image} alt={title} />
      </div>
      <div className="card-content">
        <h3 className="card-title" style={{ color: titleColor }}>{title}</h3>
        <p className="card-description">{description}</p>
      </div>
    </CardWrapper>
  );
};

export default Card; 