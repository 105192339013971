import React from 'react';
import Globe from '../components/Globe';
import ErrorBoundary from '../components/ErrorBoundary';

const Demo = () => {
  return (
    <div style={{ 
      width: '100vw',
      height: '100vh',
      position: 'relative',
      overflow: 'hidden',
      background: 'transparent'
    }}>
      <ErrorBoundary>
        <Globe />
      </ErrorBoundary>
    </div>
  );
};

export default Demo; 