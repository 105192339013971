/**
 * Ecosystem Page, Xamini Index
 * 
 * A component that displays the Xahau ecosystem partners and resources in a
 * categorized grid layout. This page serves as a directory for all official
 * and community resources related to the Xahau network.
 * 
 * Categories Include:
 * - Official Links (Network, Documentation, GitHub)
 * - Educational & Foundations
 * - Infrastructure Providers
 * - Exchanges & DEX
 * - Core Developers
 * - Events & Media
 * - Communities
 * 
 * Features:
 * - Responsive grid layout for each category
 * - External links with proper security attributes
 * - Logo display for each entity
 * - Automatic category organization
 * 
 * Data Structure:
 * ecosystemData = [
 *   {
 *     category: string,
 *     entities: [
 *       {
 *         logo: string (filename),
 *         url: string (external link)
 *       }
 *     ]
 *   }
 * ]
 * 
 * Note: All logos should be placed in the public/tables_seats directory
 */

import React from 'react';
import './Ecosystem.css';

const ecosystemData = [
  {
    category: "Official Links",
    entities: [
      { logo: "offical_xahau.png", url: "https://xahau.network/" },
      { logo: "xahau_docs.png", url: "https://docs.xahau.network/" },
      { logo: "hook_docs.png", url: "https://xrpl-hooks.readme.io/" },
      { logo: "xahau_github.png", url: "https://github.com/Xahau" },
      { logo: "xahau_x_2.png", url: "https://x.com/XahauNetwork" },
    ]
  },
  {
    category: "Educational & Foundations",
    entities: [
      { logo: "inftf.png", url: "https://inftf.org" },
      { logo: "digital_governing.png", url: "https://digitalgoverning.eu" },
    ]
  },
  {
    category: "Infrastructure Providers",
    entities: [
      { logo: "xaman.png", url: "https://xaman.app" },
      { logo: "titanium.png", url: "http://titanium.ee" },
      { logo: "evernode.png", url: "https://evernode.org" },
      { logo: "xrplwin.png", url: "https://xahau.xrplwin.com/" },
      { logo: "bithomp.png", url: "https://xahauexplorer.com/" },
      { logo: "xrpscan.png", url: "https://xahscan.com/" },
      { logo: "casinocoin.png", url: "https://casinocoin.org" },
      { logo: "xspectar.png", url: "https://xspectar.com" },
      { logo: "cbot_labs.png", url: "https://x.com/Cbot_Xrpl" },
      { logo: "commons.png", url: "https://www.xrpl-commons.org" },
      { logo: "transia.png", url: "https://transia.co" },
    ]
  },
  {
    category: "Exchanges & DEX",
    entities: [
      { logo: "bitrue.png", url: "https://www.bitrue.com" },
      { logo: "gatehub.png", url: "https://gatehub.net" },
      { logo: "magnetic.png", url: "https://xmagnetic.org/?network=mainnet" },
      { logo: "unhosted.png", url: "https://unhosted.exchange" },
      { logo: "c14_team.png", url: "https://www.c14.money" },
      { logo: "bitmart.png", url: "https://www.bitmart.com/en-US" },
    ]
  },
  {
    category: "Core Developers",
    entities: [
      { logo: "tequ.png", url: "https://tequ.dev" },
      { logo: "richard.png", url: "https://x.com/RichardXRPL" },
      { logo: "denis.png", url: "https://x.com/angell_denis" },
      { logo: "satish.png", url: "https://x.com/Satish_nl" },
    ]
  },
  {
    category: "Events & Media",
    entities: [
      { logo: "wave.png", url: "https://www.waveofinnovation.com" },
      { logo: "casa.png", url: "https://xahau.casa" },
      { logo: "xahau_network_news.png", url: "https://x.com/XahauNews" },
    ]
  },
  {
    category: "Communities",
    entities: [
      { logo: "xahau_discord.png", url: "https://discord.gg/xSHhkX4cjy" },
      { logo: "xahau_x.png", url: "https://x.com/XahauCommunity" },
    ]
  },
];

const Ecosystem = () => {
  return (
    <div className="ecosystem-page">
      <h1>Xahau Ecosystem</h1>
      <p>Explore the diverse network of entities contributing to the growth and development of the Xahau ecosystem.</p>
      
      <div className="ecosystem-grid">
        {ecosystemData.map((category, index) => (
          <div key={index} className="category-section">
            <h2>{category.category}</h2>
            <div className="entity-grid">
              {category.entities.map((entity, entityIndex) => (
                <a 
                  key={entityIndex} 
                  href={entity.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="entity-box"
                >
                  <img src={`${process.env.PUBLIC_URL}/tables_seats/${entity.logo}`} alt="" />
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ecosystem;
