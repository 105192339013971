/**
 * ContractDetails Component
 * 
 * A component that displays detailed information about a specific smart contract.
 * Accessed via the route: /contracts/:id
 * 
 * Features:
 * - Displays contract details including:
 *   • Contract name
 *   • Description
 *   • Price in XAH (Xahau tokens)
 * - Handles non-existent contract IDs with error state
 * - Uses URL parameters to fetch specific contract data
 * 
 * Props:
 * @param {Array} contracts - Array of contract objects containing:
 *   • id: number
 *   • name: string
 *   • description: string
 *   • price: number
 * 
 * Usage:
 * <ContractDetails contracts={contractsArray} />
 * 
 * Note: Requires React Router for URL parameter handling
 */

import React from 'react';
import { useParams } from 'react-router-dom';

const ContractDetails = ({ contracts }) => {
  const { id } = useParams();
  const contract = contracts.find(c => c.id === parseInt(id));

  if (!contract) {
    return <div>Contract not found</div>;
  }

  return (
    <div className="contract-details">
      <h1>{contract.name}</h1>
      <p>{contract.description}</p>
      <p>Price: {contract.price} XAH</p>
      {/* Add more details and functionality as needed */}
    </div>
  );
};

export default ContractDetails;
