import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import NFTCard from '../components/NFTCard';
import './CollectionPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faCube, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SortDropdown from '../components/SortDropdown';
import MintModal from '../components/MintModal';

const CollectionPage = () => {
  const { id } = useParams();
  const [isMintModalOpen, setIsMintModalOpen] = useState(false);
  // Mock user wallet address for testing
  const mockUserWallet = "0x123...abc";
  
  // Mock collection data (in real app, fetch this based on id)
  const collection = {
    id,
    name: "xamini trees",
    creator: "Xamini",
    description: "A collection of the coolest cats in the NFT space",
    bannerImage: "/images/xamini_trees.png",
    floorPrice: "100 XAH",
    totalItems: 10000,
    volumeTraded: "500K XAH",
    isMintable: Math.random() < 0.5
  };

  // Mock NFTs data (in real app, fetch this based on collection id)
  const mockNFTs = Array(12).fill().map((_, index) => ({
    id: `nft-${index}`,
    name: `Cool Cat #${index + 1}`,
    image: `https://picsum.photos/300/300?random=${index}`,
    collection: collection.name,
    owner: index % 3 === 0 ? mockUserWallet : `0xother${index}`,
    status: index % 4 === 0 ? 'listed' : index % 4 === 1 ? 'has_offers' : 'none',
    listPrice: index % 4 === 0 ? '2.5' : null,
    floorPrice: '1.5'
  }));

  // Add sorting handler
  const handleSort = (sortValue) => {
    // This is where you'll implement the sorting logic
    console.log('Sorting by:', sortValue);
  };

  return (
    <div className="collection-page">
      <div className="collection-header">
        <div className="banner-image">
          <img src={collection.bannerImage} alt={collection.name} />
        </div>
        <div className="collection-details">
          <h1>{collection.name}</h1>
          <p className="creator">
            by {collection.creator}
            <span className="social-icons">
              <a href="#" className="social-icon"><FontAwesomeIcon icon={faGlobe} /></a>
              <a href="#" className="social-icon"><FontAwesomeIcon icon={faTwitter} /></a>
              <a href="#" className="social-icon"><FontAwesomeIcon icon={faInstagram} /></a>
              <button 
                className={`mint-status ${!collection.isMintable ? 'minted-out' : ''}`}
                onClick={() => setIsMintModalOpen(true)}
                disabled={!collection.isMintable}
              >
                <FontAwesomeIcon icon={faCube} className="nft-icon" />
                <span className="mint-label">
                  {collection.isMintable && (
                    <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" />
                  )}
                  {collection.isMintable ? "Mint Now" : "Minted Out"}
                </span>
              </button>
            </span>
          </p>
          <p className="description">{collection.description}</p>
          <div className="stats">
            <div className="stat">
              <span className="label">Floor Price</span>
              <span className="value">{collection.floorPrice}</span>
            </div>
            <div className="stat">
              <span className="label">Items</span>
              <span className="value">{collection.totalItems}</span>
            </div>
            <div className="stat">
              <span className="label">Volume Traded</span>
              <span className="value">{collection.volumeTraded}</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className="collection-content">
        <div className="collection-controls">
          <SortDropdown onSort={handleSort} className="collection-sort" />
        </div>
        <div className="nft-grid">
          {mockNFTs.map(nft => (
            <NFTCard 
              key={nft.id}
              nft={nft}
              userWalletAddress={mockUserWallet}
            />
          ))}
        </div>
      </div>
      <MintModal 
        isOpen={isMintModalOpen}
        onClose={() => setIsMintModalOpen(false)}
        mintPrice={0.1} // Replace with actual mint price
      />
    </div>
  );
};

export default CollectionPage; 