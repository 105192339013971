import React from 'react';
import './TokenTable.css';

const getCurrencyColor = (currency) => {
  switch (currency) {
    case 'XAH':
      return 'white';
    case 'EVR':
      return '#00BFFF';
    case 'USD':
      return '#32CD32';
    case 'XRP':
      return '#4169E1';
    case 'BTC':
      return '#FFA500';
    case 'ETH':
      return '#8A2BE2';
    case 'MAG':
      return '#FF1493';
    case 'CLV':
      return '#00CED1';
    case 'ETX':
      return '#FF6347';
    case 'BZO':
      return '#20B2AA';
    case 'XTT':
      return '#9370DB';
    case 'FLY':
      return '#FFD700';
    default:
      return 'white';
  }
};

const TokenTable = ({ tokens, isLoading }) => {
  const LoadingDots = () => (
    <span className="loading-dots">
      <span></span><span></span><span></span>
    </span>
  );

  return (
    <div className="token-box__list">
      {isLoading ? (
        <div className="token-box__item">
          <span className="token-box__ticker"><LoadingDots /></span>
          <span className="token-box__amount"><LoadingDots /></span>
        </div>
      ) : tokens && tokens.length > 0 ? (
        tokens.map((token, index) => (
          <div 
            key={token.currency || index}
            className={`token-box__item ${index % 2 === 0 ? 'even' : 'odd'}`}
          >
            <div 
              className="token-box__ticker"
              style={{ color: getCurrencyColor(token.currency) }}
            >
              {token.currency || '--'}
            </div>
            <div 
              className="token-box__amount"
              style={{ color: getCurrencyColor(token.currency) }}
            >
              <span className="amount-value">{token.balance || '--'}</span>
            </div>
          </div>
        ))
      ) : (
        <div className="token-box__item">
          <span className="token-box__ticker">No Tokens Found</span>
          <span className="token-box__amount">--</span>
        </div>
      )}
    </div>
  );
};

export default TokenTable; 