import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHeart, faReply } from '@fortawesome/free-solid-svg-icons';
import { formatDistanceToNow } from 'date-fns';

/**
 * Comment Component
 * 
 * Renders a single comment and its replies in a nested structure.
 * Features include:
 * - User avatar display
 * - Username and wallet address
 * - Timestamp (relative time format)
 * - Reply functionality
 * - Upvote system
 * - Nested replies support
 * 
 * @param {Object} props
 * @param {Object} props.comment - The comment object containing:
 *   - id: unique identifier
 *   - userName: display name of the commenter
 *   - userAddress: wallet address of the commenter
 *   - timestamp: when the comment was created
 *   - text: the comment content
 *   - upvotes: number of upvotes
 *   - replies: array of nested reply comments
 * @param {string} props.userWalletAddress - Current user's wallet address
 * 
 * @returns {JSX.Element} A comment component with nested replies
 */

const Comment = ({ comment, userWalletAddress }) => {
  return (
    <div className="comment">
      <div className="user-avatar">
        <FontAwesomeIcon icon={faUser} />
      </div>
      <div className="comment-content">
        <div className="comment-header">
          <div className="comment-user">
            <span className="user-name">{comment.userName}</span>
            <span className="user-address">{comment.userAddress}</span>
          </div>
          <span className="comment-timestamp">
            {formatDistanceToNow(new Date(comment.timestamp))} ago
          </span>
        </div>
        <div className="comment-text">{comment.text}</div>
        <div className="comment-actions">
          <button className="action-button">
            <FontAwesomeIcon icon={faReply} /> Reply
          </button>
          <button className="action-button">
            <FontAwesomeIcon icon={faHeart} /> {comment.upvotes}
          </button>
        </div>
        
        {comment.replies && comment.replies.length > 0 && (
          <div className="replies">
            {comment.replies.map(reply => (
              <Comment 
                key={reply.id} 
                comment={reply}
                userWalletAddress={userWalletAddress}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment; 