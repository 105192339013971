import React from 'react';

const TradingViewTicker = () => {
  return (
    <div className="tradingview-widget-container">
      <iframe
        title="crypto-ticker"
        src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22Xahau%22%2C%22proName%22%3A%22BITRUE%3AXAHUSDT%22%7D%2C%7B%22description%22%3A%22Evernode%22%2C%22proName%22%3A%22BITRUE%3AEVRUSDT%22%7D%2C%7B%22description%22%3A%22XRP%22%2C%22proName%22%3A%22BITRUE%3AXRPUSDT%22%7D%2C%7B%22description%22%3A%22Root%20Network%22%2C%22proName%22%3A%22CRYPTO%3AROOTUSD%22%7D%2C%7B%22description%22%3A%22Solana%22%2C%22proName%22%3A%22BINANCE%3ASOLUSDT%22%7D%2C%7B%22description%22%3A%22Avalanche%22%2C%22proName%22%3A%22BINANCE%3AAVAXUSDT%22%7D%2C%7B%22description%22%3A%22Bitcoin%22%2C%22proName%22%3A%22BINANCE%3ABTCUSDT%22%7D%2C%7B%22description%22%3A%22Ethereum%22%2C%22proName%22%3A%22BINANCE%3AETHUSDT%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22regular%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%7D"
        style={{
          width: '100%',
          height: '46px',
          border: 'none',
          overflow: 'hidden',
          backgroundColor: 'transparent'
        }}
        allowtransparency="true"
        frameBorder="0"
        loading="lazy"
      />
    </div>
  );
};

export default TradingViewTicker;

