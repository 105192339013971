import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageGallery from './ImageGallery';
import Card from './Card';
import PriceChart from './PriceChart';
import './Home.css';

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="home-container">
      <div className="home">
        <section className="banner-section">
          <Link to="/xrp-meetups" className="banner-image">
            <img 
              src="/images/events.png" 
              alt="Featured Collections"
              style={{ 
                display: 'block',
                objectPosition: 'center center',
                width: '100%',
                height: '100%',
                margin: 0,
                padding: 0
              }}
            />
            <div className="banner-text">
              <h1>Global Crypto Events</h1>
              <span className="status">Now Open</span>
            </div>
          </Link>
        </section>

        <section style={{
          padding: '40px 0',
          background: 'linear-gradient(135deg, #0d1c28 0%, #1a2c3d 100%)',
          borderRadius: '12px',
          margin: '20px 0'
        }}>
          <div style={{
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0 20px'
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '40px',
              flexDirection: windowWidth <= 768 ? 'column' : 'row'
            }}>
              <div style={{ 
                flex: 1,
                textAlign: windowWidth <= 768 ? 'center' : 'left',
                padding: windowWidth <= 768 ? '20px 0' : '0'
              }}>
                <h2 style={{
                  color: '#61dafb',
                  fontSize: windowWidth <= 768 ? '2rem' : '2.5rem',
                  marginBottom: '10px'
                }}>🚀 Xahau Dev Contest 2025</h2>
                <h3 style={{
                  color: '#ffffff',
                  fontSize: windowWidth <= 768 ? '1.5rem' : '1.8rem',
                  marginBottom: '20px'
                }}>Build the Future of Blockchain</h3>
                <div style={{
                  fontSize: windowWidth <= 768 ? '1.2rem' : '1.4rem',
                  color: '#ffffff',
                  marginBottom: '20px'
                }}>
                  <span style={{ color: '#4CAF50', fontWeight: 'bold' }}>$4,000 USDT</span>
                  {' + '}
                  <span style={{ color: '#4CAF50', fontWeight: 'bold' }}>35,000 XAH</span>
                  {' in Prizes'}
                </div>
                <ul style={{
                  listStyle: 'none',
                  padding: 0,
                  marginBottom: '30px',
                  color: '#ffffff',
                  fontSize: windowWidth <= 768 ? '1rem' : '1.1rem',
                  textAlign: windowWidth <= 768 ? 'left' : 'left',
                  maxWidth: windowWidth <= 768 ? '300px' : 'none',
                  margin: windowWidth <= 768 ? '0 auto 30px' : '0 0 30px'
                }}>
                  <li style={{ marginBottom: '10px' }}>🏆 First Prize: $2.5K USDT + 20K XAH</li>
                  <li style={{ marginBottom: '10px' }}>📅 Jan 13 - Jan 31, 2025</li>
                  <li style={{ marginBottom: '10px' }}>💡 Open Theme - Build Anything on Xahau</li>
                  <li style={{ marginBottom: '10px' }}>🔧 Leverage Native Smart Contracts (Hooks)</li>
                </ul>
                <a 
                  href="https://github.com/XRPL-Labs/Xahau-Dev-Contest/?tab=readme-ov-file" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{
                    display: 'inline-block',
                    background: '#61dafb',
                    color: '#0d1c28',
                    padding: windowWidth <= 768 ? '10px 20px' : '12px 24px',
                    borderRadius: '6px',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    fontSize: windowWidth <= 768 ? '1rem' : '1.1rem',
                    transition: 'all 0.3s ease',
                    width: windowWidth <= 768 ? '80%' : 'auto',
                    maxWidth: '300px'
                  }}
                >
                  Join the Contest →
                </a>
              </div>
              <div style={{ 
                flex: 1,
                padding: windowWidth <= 768 ? '0 20px' : '0'
              }}>
                <img 
                  src="/images/398994445-42dcf26c-ca2f-43b8-96ee-132001dc9651.png" 
                  alt="Xahau Dev Contest"
                  style={{
                    width: '100%',
                    maxWidth: windowWidth <= 768 ? '100%' : '500px',
                    borderRadius: '12px',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.2)'
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whats-new-section">
          <h2 className="section-title">What's new on Xahau?</h2>
          <div className="cards-grid">
            <Card
              image="/images/gov.png"
              title="Governance"
              description="Explore the Xahau governance system"
              link="/governance"
              titleColor="#2A9D8F"
            />
            <Card
              image="/images/xahau_radio.png"
              title="Xahau Radio"
              description="Lo-Fi radio streaming with Xahau technology"
              link="https://xahauradio.com"
              titleColor="#F4A261"
            />
            <Card
              image="/images/vpra.jpeg"
              title="VPRA"
              description="NFT game built on the Xahau blockchain"
              link="https://vpra.app/home"
              isExternal={true}
              titleColor="#BC4749"
            />
          </div>
        </section>
        
        {/* <section className="image-gallery-section">
          <h2 className="section-title">Featured NFTs</h2>
          <ImageGallery />
        </section> */}

<section className="price-chart-section">
  <div className="price-chart-card">
    <h3>Xahau Price</h3>
    <PriceChart tokenSymbol="XAH" />
  </div>
  <div className="price-chart-card">
    <h3>XRP Price</h3>
    <PriceChart tokenSymbol="XRP" />
  </div>
  <div className="price-chart-card">
    <h3>Evernode Price</h3>
    <PriceChart tokenSymbol="EVR" />
  </div>
</section>

      </div>
    </div>
  );
};

export default Home;