import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import NFTCard from '../components/NFTCard';
import { mockNfts } from '../mockData/nftData';
import TokenTable from '../components/TokenTable';
import { mockTokens } from '../mockData/tokenData';
import HookSlots from '../components/HookSlots';
import SortDropdown from '../components/SortDropdown';
import TabsDropdown from '../components/TabsDropdown';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('transactions');
  const [walletDetails, setWalletDetails] = useState({
    address: 'rXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    balance: '0',
    lastActivity: null,
    hooks: [],
    trustlines: []
  });
  const [transactions, setTransactions] = useState([]);
  const [nfts, setNfts] = useState(mockNfts);
  const [tokens, setTokens] = useState(mockTokens);
  const [isLoading, setIsLoading] = useState(false);
  const [hooks, setHooks] = useState([]);

  // Generate mock transactions
  useEffect(() => {
    const transactionTypes = [
      'Payment', 'Invoke', 'Import', 'GenesisMint', 'URITokenBurn',
      'ClaimReward', 'EscrowCreate', 'EscrowFinish', 'EscrowCancel',
      'AccountSet', 'TrustSet', 'OfferCreate', 'OfferCancel', 'SetHook'
    ];
    
    const mockTransactions = Array.from({ length: 100 }, (_, index) => {
      const randomType = transactionTypes[Math.floor(Math.random() * transactionTypes.length)];
      const randomAmount = (Math.random() * 1000).toFixed(2);
      const randomDate = new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000);
      
      return {
        id: index,
        type: randomType,
        date: randomDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }),
        amount: randomAmount,
        direction: Math.random() > 0.5 ? 'in' : 'out'
      };
    });

    setTransactions(mockTransactions);
  }, []);

  const handleSort = (sortValue) => {
    let sortedNfts = [...nfts];
    
    switch (sortValue) {
      case 'name_asc':
        sortedNfts.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name_desc':
        sortedNfts.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'price_asc':
        sortedNfts.sort((a, b) => {
          const priceA = parseFloat(a.xahPrice || 0);
          const priceB = parseFloat(b.xahPrice || 0);
          return priceA - priceB;
        });
        break;
      case 'price_desc':
        sortedNfts.sort((a, b) => {
          const priceA = parseFloat(a.xahPrice || 0);
          const priceB = parseFloat(b.xahPrice || 0);
          return priceB - priceA;
        });
        break;
      case 'date_asc':
        sortedNfts.sort((a, b) => new Date(a.mintDate) - new Date(b.mintDate));
        break;
      case 'date_desc':
        sortedNfts.sort((a, b) => new Date(b.mintDate) - new Date(a.mintDate));
        break;
      default:
        break;
    }
    
    setNfts(sortedNfts);
  };

  return (
    <div className="dashboard-container">
      {/* Wallet Overview Section */}
      <div className="wallet-overview">
        <div className="wallet-card main-balance">
          <div className="balance-container">
            <div className="balance-section">
              <h3>Balance</h3>
              <div className="balance-row">
                <span className="balance-amount">{walletDetails.balance}</span>
                <span className="currency">XAH</span>
              </div>
            </div>
            <div className="wallet-section">
              <h3>Wallet</h3>
              <div className="balance-row">
                <span className="wallet-address">{walletDetails.address}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="wallet-details-grid">
          <div className="wallet-card">
            <h3>Account Worth</h3>
            <p>
              <span className="amount">$1,234.56</span>
              <span className="currency-usd">USD</span>
            </p>
          </div>
          <div className="wallet-card">
            <h3>Trustlines</h3>
            <p>{walletDetails.trustlines.length} Active Trustlines</p>
          </div>
          <div className="wallet-card">
            <h3>NFTs</h3>
            <p>{nfts.length} NFTs Owned</p>
          </div>
          <div className="wallet-card">
            <h3>Hooks</h3>
            <p>{walletDetails.hooks.length} Active Hooks</p>
          </div>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="tabs-section mobile-friendly">
        <div className="tabs-header">
          <div className="tabs-header__left desktop-only">
            <button 
              className={`tab-button ${activeTab === 'transactions' ? 'active' : ''}`}
              onClick={() => setActiveTab('transactions')}
            >
              Transactions
            </button>
            <button 
              className={`tab-button ${activeTab === 'tokens' ? 'active' : ''}`}
              onClick={() => setActiveTab('tokens')}
            >
              Tokens
            </button>
            <button 
              className={`tab-button ${activeTab === 'nfts' ? 'active' : ''}`}
              onClick={() => setActiveTab('nfts')}
            >
              NFTs
            </button>
            <button 
              className={`tab-button ${activeTab === 'hooks' ? 'active' : ''}`}
              onClick={() => setActiveTab('hooks')}
            >
              Hooks
            </button>
          </div>
          <div className="tabs-header__mobile">
            <TabsDropdown activeTab={activeTab} onTabChange={setActiveTab} />
          </div>
          {activeTab === 'nfts' && (
            <SortDropdown onSort={handleSort} className="tabs-header__sort" />
          )}
        </div>

        <div className="tab-content mobile-view">
          {activeTab === 'transactions' ? (
            <div className="transaction-box__list">
              {transactions.map((tx, index) => (
                <div 
                  key={tx.id}
                  className={`transaction-box__item ${index % 2 === 0 ? 'even' : 'odd'}`}
                >
                  <div className={`transaction-box__type ${tx.type}`}>
                    {tx.type}
                  </div>
                  <div className="transaction-box__date">
                    {tx.date}
                  </div>
                  <div className="transaction-box__amount">
                    <span className="amount-value">
                      {tx.direction === 'in' ? '+' : '-'}{tx.amount}
                    </span>
                    <span className="amount-currency">XAH</span>
                  </div>
                </div>
              ))}
            </div>
          ) : activeTab === 'tokens' ? (
            <TokenTable tokens={tokens} isLoading={isLoading} />
          ) : activeTab === 'nfts' ? (
            <div className="nfts-container">
              <div className="nfts-grid">
                {nfts.map(nft => (
                  <NFTCard key={nft.id} nft={nft} userWalletAddress={walletDetails.address} />
                ))}
              </div>
            </div>
          ) : (
            <HookSlots hooks={hooks} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
