import React, { useState, useEffect, useRef } from 'react';
import './EventMenu.css';

const calculateDaysUntil = (eventDate) => {
  if (!eventDate || eventDate.includes('TBA')) return null;
  
  // Handle date ranges by taking the first date
  const firstDate = eventDate.split('-')[0].trim();
  
  // Split the date if it contains a time portion
  const dateStr = firstDate.split(',')[0]; // Get just the date part
  const [month, day, year] = dateStr.split(' ');
  
  // Create date string in a format JavaScript can reliably parse
  const formattedDate = `${month} ${day.replace(/\D/g, '')} ${year || new Date().getFullYear()}`;
  const targetDate = new Date(formattedDate);
  const today = new Date();
  
  // Reset time part to compare dates only
  today.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);
  
  const timeDiff = targetDate - today;
  const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  
  return daysRemaining > 0 ? daysRemaining : null;
};

const EventMenu = ({ events, onEventSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const touchStartX = useRef(null);
  const touchStartY = useRef(null);
  const menuRef = useRef(null);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = (e) => {
    if (!touchStartX.current || !touchStartY.current) return;

    const touchEndX = e.changedTouches[0].clientX;
    const touchEndY = e.changedTouches[0].clientY;

    // Calculate swipe distance
    const deltaX = touchEndX - touchStartX.current;
    const deltaY = touchEndY - touchStartY.current;

    // Check if horizontal swipe is more significant than vertical
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Minimum swipe distance threshold (in pixels)
      const minSwipeDistance = 50;

      if (Math.abs(deltaX) >= minSwipeDistance) {
        // Right to left swipe
        if (deltaX < 0 && !isOpen) {
          setIsOpen(true);
        }
        // Left to right swipe
        else if (deltaX > 0 && isOpen) {
          setIsOpen(false);
        }
      }
    }

    // Reset touch coordinates
    touchStartX.current = null;
    touchStartY.current = null;
  };

  // Function to check if screen is mobile size
  const isMobileScreen = () => window.innerWidth <= 768; // adjust breakpoint as needed

  // Modified event select handler
  const handleEventSelect = (event) => {
    if (isMobileScreen()) {
      setIsOpen(false); // Close menu only on mobile
    }
    onEventSelect(event);
  };

  // Group events by month
  const groupedEvents = events.reduce((groups, event) => {
    // Skip TBA dates
    if (event.date.includes('TBA')) {
      if (!groups['TBA']) groups['TBA'] = [];
      groups['TBA'].push(event);
      return groups;
    }

    // Get month from date
    const date = new Date(event.date.split('-')[0]);
    const month = date.toLocaleString('default', { month: 'long' });
    
    if (!groups[month]) {
      groups[month] = [];
    }
    groups[month].push(event);
    return groups;
  }, {});

  // Sort months chronologically
  const sortedMonths = Object.keys(groupedEvents).sort((a, b) => {
    if (a === 'TBA') return 1;
    if (b === 'TBA') return -1;
    const monthA = new Date(`${a} 1, 2024`);
    const monthB = new Date(`${b} 1, 2024`);
    return monthA - monthB;
  });

  return (
    <div 
      ref={menuRef}
      className={`event-menu ${isOpen ? 'open' : ''}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div 
        className="event-menu-toggle" 
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="toggle-icon">{isOpen ? '←' : '→'}</span>
      </div>
      <div className="event-menu-content">
        <h2 className="event-menu-title">
          Global Events
          <span className="event-count">{events.length}</span>
        </h2>
        <h3 className="event-menu-subtitle">Coming Up Next...</h3>
        <div className="event-list">
          {sortedMonths.map((month, monthIndex) => (
            <div key={month} className="month-group">
              <h4 className="month-header">{month}</h4>
              {groupedEvents[month]
                .sort((a, b) => {
                  const daysUntilA = calculateDaysUntil(a.date);
                  const daysUntilB = calculateDaysUntil(b.date);
                  
                  // Handle TBA dates
                  if (!daysUntilA && daysUntilB) return 1;
                  if (daysUntilA && !daysUntilB) return -1;
                  if (!daysUntilA && !daysUntilB) return 0;
                  
                  // Sort by days until event
                  return daysUntilA - daysUntilB;
                })
                .map((event, eventIndex) => (
                  <div 
                    key={event.id} 
                    className="event-item"
                    onClick={() => handleEventSelect(event)}
                  >
                    <div className="countdown-container">
                      <div className="event-content">
                        <h3>{event.name}</h3>
                        <p className="event-date">{event.date}</p>
                        <p className="event-city large-city">{event.city}</p>
                      </div>
                      <div className="countdown-display">
                        <div className="big-number">
                          {calculateDaysUntil(event.date) || 'TBA'}
                        </div>
                        <div className="countdown-label">Days Until Event</div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventMenu; 