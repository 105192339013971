import React from 'react';
import CollectionsGallery from '../components/CollectionsGallery';
import './Collections.css';

const Collections = () => {
  return (
    <div className="collections-container">
      <h1>Collection Gallery</h1>
      <div className="collections-content">
        <CollectionsGallery />
      </div>
    </div>
  );
};

export default Collections; 