/**
 * Mock Data for Discussion Components
 * 
 * This file contains sample data structures for development and testing
 * of the discussion feature. It provides examples of:
 * - Single comments
 * - Nested replies
 * - Various user types
 * - Different timestamp formats
 * - Upvote counts
 * 
 * Data Structure:
 * comment: {
 *   id: number
 *   userName: string (format: "name.domain")
 *   userAddress: string (format: "rXXXXXXXXXXXXXXXX")
 *   text: string
 *   timestamp: string (ISO format)
 *   upvotes: number
 *   replies?: Array<comment>
 * }
 * 
 * Note: This is temporary test data.
 * TODO: Replace with actual API integration
 */

export const mockComments = [
  {
    id: 1,
    userName: "alice.eth",
    userAddress: "rXXXXXXXXXXXXXXXX1",
    text: "This proposal shows great potential for scaling our ecosystem. I particularly like the focus on developer tooling and documentation.",
    timestamp: "2024-03-10T10:00:00Z",
    upvotes: 23,
    replies: [
      {
        id: 2,
        userName: "bob.lens",
        userAddress: "rXXXXXXXXXXXXXXXX2",
        text: "Agreed! The technical specifications are well thought out.",
        timestamp: "2024-03-10T11:00:00Z",
        upvotes: 8,
      }
    ]
  },
  {
    id: 3,
    userName: "carol.sol",
    userAddress: "rXXXXXXXXXXXXXXXX3",
    text: "Have you considered integrating with existing protocols? This could potentially increase the impact of the project.",
    timestamp: "2024-03-10T09:00:00Z",
    upvotes: 15,
  }
]; 