/**
 * DiscussionThread Component
 * 
 * The main container component for the proposal discussion feature.
 * Manages the state of comments and coordinates between child components.
 * 
 * Features:
 * - Comment state management
 * - New comment submission handling
 * - Display of comment count
 * - Integration of CommentInput and CommentList components
 * 
 * Current Implementation Notes:
 * - Using mockComments for development (TODO: Replace with API integration)
 * - Comments are stored in reverse chronological order
 * - User identification is handled via wallet address
 * 
 * @param {Object} props
 * @param {string} props.proposalId - The ID of the proposal being discussed
 * @param {string} props.userWalletAddress - The current user's wallet address
 * 
 * @returns {JSX.Element} The complete discussion interface
 */

import React, { useState } from 'react';
import CommentInput from './CommentInput';
import CommentList from './CommentList';
import { mockComments } from './mockData';
import './Discussion.css';

const DiscussionThread = ({ proposalId, userWalletAddress }) => {
  const [comments, setComments] = useState(mockComments);

  const handleSubmitComment = (text) => {
    // TODO: Replace with API call
    const newComment = {
      id: comments.length + 1,
      userName: userWalletAddress.substring(0, 8) + "...",
      userAddress: userWalletAddress,
      text,
      timestamp: new Date(),
      upvotes: 0,
      replies: []
    };
    setComments([newComment, ...comments]);
  };

  return (
    <div className="discussion-wrapper">
      <div className="discussion-section grid-column-span">
        <h2>Discussion ({comments.length})</h2>
        <CommentInput 
          userWalletAddress={userWalletAddress}
          onSubmit={handleSubmitComment}
        />
        <CommentList 
          comments={comments}
          userWalletAddress={userWalletAddress}
        />
      </div>
    </div>
  );
};

export default DiscussionThread; 