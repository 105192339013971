import React, { useState } from 'react';
import './MultiDex.css';

const TOKENS = [
  { symbol: 'XRP', name: 'Ripple', icon: '/images/tokens/xrp.png' },
  { symbol: 'XAH', name: 'Xahau', icon: '/images/tokens/xah.png' },
  { symbol: 'EVR', name: 'Evernode', icon: '/images/tokens/evr.png' },
  { symbol: 'ROOT', name: 'Root', icon: '/images/tokens/root.png' },
  { symbol: 'SOL', name: 'Solana', icon: '/images/tokens/sol.png' },
  { symbol: 'ETH', name: 'Ethereum', icon: '/images/tokens/eth.png' },
  { symbol: 'RLUSD', name: 'RealUSD', icon: '/images/tokens/rlusd.png' }
];

const MultiDex = () => {
  const [fromToken, setFromToken] = useState(TOKENS[0]);
  const [toToken, setToToken] = useState(TOKENS[1]);
  const [amount, setAmount] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleSwap = () => {
    // Implement swap logic here
    console.log(`Swapping ${amount} ${fromToken.symbol} to ${toToken.symbol}`);
  };

  const switchTokens = () => {
    const temp = fromToken;
    setFromToken(toToken);
    setToToken(temp);
  };

  return (
    <div className="multidex-container">
      <div className="multidex-card">
        <h1 className="multidex-title">Multi DEX</h1>
        
        <div className="swap-container">
          <div className="token-input-container">
            <label>From</label>
            <div className="token-input">
              <select 
                value={fromToken.symbol}
                onChange={(e) => setFromToken(TOKENS.find(t => t.symbol === e.target.value))}
              >
                {TOKENS.map(token => (
                  <option key={token.symbol} value={token.symbol}>
                    {token.symbol}
                  </option>
                ))}
              </select>
              <input
                type="number"
                placeholder="0.0"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>

          <button className="switch-button" onClick={switchTokens}>
            ↕️
          </button>

          <div className="token-input-container">
            <label>To</label>
            <div className="token-input">
              <select
                value={toToken.symbol}
                onChange={(e) => setToToken(TOKENS.find(t => t.symbol === e.target.value))}
              >
                {TOKENS.map(token => (
                  <option key={token.symbol} value={token.symbol}>
                    {token.symbol}
                  </option>
                ))}
              </select>
              <input
                type="number"
                placeholder="0.0"
                value={(parseFloat(amount) * 1.5).toString()} // Dummy conversion rate
                disabled
              />
            </div>
          </div>

          <div className="swap-info">
            <p>1 {fromToken.symbol} ≈ 1.5 {toToken.symbol}</p>
            <button onClick={() => setShowAdvanced(!showAdvanced)}>
              {showAdvanced ? 'Hide' : 'Show'} Advanced Options
            </button>
          </div>

          {showAdvanced && (
            <div className="advanced-options">
              <div className="slippage-setting">
                <label>Slippage Tolerance</label>
                <select defaultValue="0.5">
                  <option value="0.1">0.1%</option>
                  <option value="0.5">0.5%</option>
                  <option value="1.0">1.0%</option>
                </select>
              </div>
            </div>
          )}

          <button 
            className="swap-button"
            onClick={handleSwap}
            disabled={!amount || amount === '0'}
          >
            Swap
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultiDex; 