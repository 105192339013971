import React from 'react';
import './HookSlots.css';

// Mock hook data
const mockHooks = [
  {
    id: 0,
    status: 'active',
    HookHash: 'C1963BD65F4735A4B5AE56C96B79D2E828AF291BFAB3E22C1B89F5B7DE',
    HookNamespace: '0000000000000000000000000000000000000000000000000000000000000000',
    HookParameters: 'iVault',
    LastUpdate: '2024-03-15'
  },
  {
    id: 2,
    status: 'active',
    HookHash: 'D7842AF63B4735A4B5AE56C96B79D2E828AF291BFAB3E22C1B89F5B7DE',
    HookNamespace: '1111111111111111111111111111111111111111111111111111111111111111',
    HookParameters: 'xTokenize',
    LastUpdate: '2024-03-14'
  },
  {
    id: 5,
    status: 'active',
    HookHash: 'E9273BD65F4735A4B5AE56C96B79D2E828AF291BFAB3E22C1B89F5B7DE',
    HookNamespace: '2222222222222222222222222222222222222222222222222222222222222222',
    HookParameters: 'Remit',
    LastUpdate: '2024-03-13'
  }
];

const HookSlots = () => {
  // Initialize 10 hook slots, filling with mock data where available
  const hookSlots = Array(10).fill(null).map((_, index) => {
    const mockHook = mockHooks.find(hook => hook.id === index);
    return {
      id: index,
      status: mockHook ? 'active' : 'empty',
      ...mockHook
    };
  });

  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return '#32CD32'; // Lime Green
      case 'inactive':
        return '#FF6B6B'; // Light Coral
      default:
        return '#808080'; // Gray for empty
    }
  };

  return (
    <div className="hook-slots">
      <div className="hook-slots__grid">
        {hookSlots.map((slot) => (
          <div 
            key={slot.id} 
            className={`hook-slot ${slot.status}`}
          >
            <div className="hook-slot__header">
              <span className="hook-slot__number">Hook Slot {slot.id}</span>
              <span 
                className="hook-slot__status"
                style={{ color: getStatusColor(slot.status) }}
              >
                {slot.status.charAt(0).toUpperCase() + slot.status.slice(1)}
              </span>
            </div>
            {slot.status === 'active' && (
              <div className="hook-slot__details">
                <div className="hook-slot__parameter">
                  {slot.HookParameters}
                </div>
                <div className="hook-slot__hash" title={slot.HookHash}>
                  Hash: {`${slot.HookHash.substring(0, 8)}...${slot.HookHash.substring(slot.HookHash.length - 8)}`}
                </div>
                <div className="hook-slot__namespace" title={slot.HookNamespace}>
                  NS: {`${slot.HookNamespace.substring(0, 8)}...${slot.HookNamespace.substring(slot.HookNamespace.length - 8)}`}
                </div>
                <div className="hook-slot__date">
                  Updated: {slot.LastUpdate}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HookSlots; 