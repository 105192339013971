export const generateRandomCollections = (count) => {
  const collectionNames = [
    'Cosmic', 'Digital', 'Pixel', 'Neo', 'Cyber', 'Meta', 'Virtual', 'Quantum', 'Crystal', 'Shadow'
  ];
  
  const suffixes = [
    'Punks', 'Dragons', 'Warriors', 'Legends', 'Heroes', 'Knights', 'Wizards', 'Titans', 'Gods', 'Spirits'
  ];
  
  const creators = [
    'CryptoMaster', 'DigitalArtist', 'NFTCreator', 'BlockchainArt', 'Web3Studio', 
    'MetaLabs', 'PixelPro', 'ArtBlock', 'NFTWizard', 'CryptoStudio'
  ];

  const images = [
    '/images/anxels.png',
    '/images/azzerpardis.png',
    '/images/beast_belles.png',
    '/images/goat_club.png',
    '/images/hook_bots.png',
    '/images/living_legacy.png',
    '/images/monks.png',
    '/images/winnie.png',
    '/images/xahau_cows.png',
    '/images/xahau_eggs.png',
    '/images/xSoldiers.png'
  ];

  return Array.from({ length: count }, (_, i) => ({
    id: i + 1,
    name: `${collectionNames[Math.floor(Math.random() * collectionNames.length)]} ${
      suffixes[Math.floor(Math.random() * suffixes.length)]
    }`,
    creator: creators[Math.floor(Math.random() * creators.length)],
    totalItems: Math.floor(Math.random() * 9000) + 1000,
    floorPrice: `${(Math.random() * 2 + 0.1).toFixed(2)} XAH`,
    volumeTraded: `${Math.floor(Math.random() * 50000) + 5000} XAH`,
    bannerImage: images[Math.floor(Math.random() * images.length)],
    description: `A unique collection of digital collectibles on the Xahau network.`,
  }));
}; 