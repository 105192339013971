import React from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faVoteYea, 
  faClock, 
  faCheckCircle, 
  faSpinner,
  faCalendarAlt,
  faUsers,
  faWallet
} from '@fortawesome/free-solid-svg-icons';
import './GrantDetail.css';
import DiscussionThread from '../components/Discussion/DiscussionThread';

const GrantDetail = ({ userWalletAddress }) => {
  const { id } = useParams();
  const [grant, setGrant] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchGrantData = async () => {
      try {
        // Mock data - replace with actual API call
        const mockGrant = {
          id,
          title: "Cross-Chain Bridge Development",
          status: "open-for-voting",
          requestedAmount: "50000",
          currentVotes: "32000",
          requiredVotes: "100000",
          timeline: "3 months",
          category: "Development",
          proposer: "rXXXXXXXXXXXXXXXXXXXXXXX",
          dateSubmitted: "2024-03-15",
          votingEnds: "2024-04-15",
          description: `This proposal aims to develop a secure and efficient cross-chain bridge 
            between Xahau and other major blockchain networks. The bridge will enable seamless 
            asset transfers and enhance the overall ecosystem connectivity.`,
          milestones: [
            {
              title: "Architecture Design",
              description: "Complete system architecture and security specifications",
              timeline: "1 month",
              deliverables: ["Technical documentation", "Security audit plan"]
            },
            {
              title: "Implementation",
              description: "Develop core bridge components and smart contracts",
              timeline: "1.5 months",
              deliverables: ["Bridge contracts", "Testing framework"]
            },
            {
              title: "Testing & Deployment",
              description: "Comprehensive testing and mainnet deployment",
              timeline: "0.5 months",
              deliverables: ["Audit report", "Production deployment"]
            }
          ],
          team: [
            {
              role: "Lead Developer",
              experience: "8 years in blockchain development"
            },
            {
              role: "Security Engineer",
              experience: "5 years in smart contract security"
            }
          ],
          budget: {
            development: "30000",
            security: "10000",
            operations: "10000"
          }
        };

        setGrant(mockGrant);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching grant:", error);
        setLoading(false);
      }
    };

    fetchGrantData();
  }, [id]);

  if (loading) {
    return (
      <div className="grant-detail-loading">
        Loading
        <div className="loading-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  if (!grant) {
    return <div className="grant-detail-error">Grant proposal not found</div>;
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'open-for-voting': return faVoteYea;
      case 'in-review': return faClock;
      case 'approved': return faCheckCircle;
      case 'in-progress': return faSpinner;
      default: return faVoteYea;
    }
  };

  return (
    <div className="grant-detail-container">
      <div className="grant-detail-content">
        <div className="grant-detail-left">
          <h1>{grant.title}</h1>
          
          <div className="grant-status-banner">
            <div className={`grant-status ${grant.status}`}>
              <FontAwesomeIcon icon={getStatusIcon(grant.status)} className="status-icon" />
              {grant.status.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </div>
            <div className={`grant-category ${grant.category}`}>
              {grant.category}
            </div>
          </div>

          <div className="grant-metrics">
            <div className="metric">
              <FontAwesomeIcon icon={faWallet} />
              <div className="metric-content">
                <span className="metric-label">Requested Amount</span>
                <span className="metric-value">{grant.requestedAmount} XAH</span>
              </div>
            </div>
            <div className="metric">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <div className="metric-content">
                <span className="metric-label">Timeline</span>
                <span className="metric-value">{grant.timeline}</span>
              </div>
            </div>
            <div className="metric">
              <FontAwesomeIcon icon={faUsers} />
              <div className="metric-content">
                <span className="metric-label">Current Votes</span>
                <span className="metric-value">{grant.currentVotes} / {grant.requiredVotes}</span>
              </div>
            </div>
          </div>

          <div className="grant-section">
            <h2>Description</h2>
            <p>{grant.description}</p>
          </div>

          <div className="grant-section">
            <h2>Milestones</h2>
            <div className="milestones-list">
              {grant.milestones.map((milestone, index) => (
                <div key={index} className="milestone-item">
                  <h3>{milestone.title}</h3>
                  <p>{milestone.description}</p>
                  <div className="milestone-details">
                    <span>Timeline: {milestone.timeline}</span>
                    <div className="deliverables">
                      <strong>Deliverables:</strong>
                      <ul>
                        {milestone.deliverables.map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grant-detail-right">
          <div className="voting-panel">
            <h2>Voting Progress</h2>
            <div className="progress-bar">
              <div 
                className="progress" 
                style={{ width: `${(parseInt(grant.currentVotes) / parseInt(grant.requiredVotes)) * 100}%` }}
              ></div>
            </div>
            <div className="voting-stats">
              <span>{grant.currentVotes} XAH voted</span>
              <span>{grant.requiredVotes} XAH required</span>
            </div>
            
            <div className="voting-action">
              <input 
                type="number" 
                placeholder="Enter amount to vote" 
                className="vote-input"
              />
              <button className="vote-button">Cast Vote</button>
            </div>

            <div className="voting-info">
              <p>Voting ends: {grant.votingEnds}</p>
            </div>
          </div>

          <div className="grant-section">
            <h2>Team</h2>
            <div className="team-list">
              {grant.team.map((member, index) => (
                <div key={index} className="team-member">
                  <span className="role">{member.role}</span>
                  <span className="experience">{member.experience}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="grant-section">
            <h2>Budget Breakdown</h2>
            <div className="budget-list">
              {Object.entries(grant.budget).map(([category, amount]) => (
                <div key={category} className="budget-item">
                  <span className="category">{category}</span>
                  <span className="amount">{amount} XAH</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <DiscussionThread 
        proposalId={id}
        userWalletAddress={userWalletAddress}
      />
    </div>
  );
};

export default GrantDetail; 