/**
 * Footer Component
 * 
 * Renders the site-wide footer containing:
 * - Copyright information
 * - (Commented out sections include:)
 *   - Logo
 *   - Social media links (X/Twitter, GitHub)
 *   - Ecosystem links (Xahau)
 *   - Navigation links
 *   - Privacy Policy and Terms of Service links
 * 
 * @component
 * @example
 * return (
 *   <Footer />
 * )
 */

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Company Section */}
        <div className="footer-section company-section">
          <div className="footer-logo">
            <img src="/tables_seats/xamini.png" alt="Xamini" className="footer-logo-img" />
          </div>
          <p className="company-description">
            Building innovative solutions for the Xahau and XRPL ecosystems.
          </p>
        </div>

        {/* Contact Section */}
        <div className="footer-section contact-section">
          <h3>Contact Us</h3>
          <div className="footer-social-icons">
            <a href="https://x.com/dazzlingxrpl" target="_blank" rel="noopener noreferrer">
              <img src="/x.svg" alt="X (Twitter)" className="footer-social-icon" />
            </a>
            <a href="https://github.com/dazzlingxrpl" target="_blank" rel="noopener noreferrer">
              <img src="/github.svg" alt="GitHub" className="footer-social-icon github" />
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p className="copyright">&copy; 2024 Xamini. All rights reserved.</p>
          <div className="supported-networks">
            <span>Powered by</span>
            <a href="https://xahau.network/" target="_blank" rel="noopener noreferrer">
              <img src="/xahau.svg" alt="Xahau" className="network-icon" />
            </a>
            <a href="https://xrpl.org/" target="_blank" rel="noopener noreferrer">
              <img src="/xrpl.svg" alt="XRPL" className="network-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

