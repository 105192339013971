export const mockNfts = Array.from({ length: 27 }, (_, index) => {
  // Determine ownership and status more systematically
  const isOwned = index % 3 === 0;
  const statusOptions = ['listed', 'has_offers', 'not_listed', 'for_sale'];
  const status = statusOptions[index % 4];
  
  return {
    id: `nft-${index + 1}`,
    name: `Xahau Creature #${index + 1}`,
    collection: 'Xahau Creatures',
    image: `https://picsum.photos/300/300?random=${index}`,
    owner: isOwned ? 'rXXXXXXXXXXXXXXXXXXXXXXXXXXX' : `r${Math.random().toString(36).substring(2, 15)}`,
    status: status,
    listPrice: status === 'listed' || status === 'for_sale' ? (Math.random() * 1000 + 500).toFixed(2) : null,
    floorPrice: (Math.random() * 500).toFixed(2),
    topOffer: status === 'has_offers' ? (Math.random() * 800).toFixed(2) : null,
    attributes: [
      { trait_type: 'Background', value: ['Cosmic', 'Desert', 'Ocean'][index % 3] },
      { trait_type: 'Rarity', value: ['Common', 'Rare', 'Epic', 'Legendary'][index % 4] }
    ],
    // Add price history for context
    priceHistory: {
      lastSale: status !== 'not_listed' ? (Math.random() * 1200 + 300).toFixed(2) : null,
      previousPrice: (Math.random() * 1000 + 200).toFixed(2)
    }
  };
}); 