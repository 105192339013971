// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collections-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.collections-content {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .collections-container {
    padding: 1rem;
  }
} `, "",{"version":3,"sources":["webpack://./src/pages/Collections.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,cAAc;EACd,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".collections-container {\n  padding: 20px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.collections-content {\n  margin-top: 1rem;\n}\n\n@media (max-width: 768px) {\n  .collections-container {\n    padding: 1rem;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
