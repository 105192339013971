export const mockTokens = [
  { currency: 'XAH', balance: '1234.56' },
  { currency: 'EVR', balance: '5000.00' },
  { currency: 'USD', balance: '10000.00' },
  { currency: 'XRP', balance: '2500.75' },
  { currency: 'BTC', balance: '0.45' },
  { currency: 'ETH', balance: '3.21' },
  { currency: 'MAG', balance: '15000.00' },
  { currency: 'CLV', balance: '7500.25' },
  { currency: 'ETX', balance: '950.00' },
  { currency: 'BZO', balance: '4200.50' },
  { currency: 'XTT', balance: '6300.75' },
  { currency: 'FLY', balance: '8100.25' },
]; 