import React from 'react';
import { useParams } from 'react-router-dom';
import './NFTDetail.css';

const NFTDetail = ({ userWalletAddress }) => {
  const { id } = useParams();
  const [nft, setNft] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const getRandomTransactionColor = () => {
    const colors = [
      '#64DD1766',   // Payment (Light Green)
      '#FFD60066',   // ClaimReward (Yellow)
      '#E91E6366',   // URITokenSell (Pink)
      '#29B6F666',   // Invoke (Light Blue)
      '#7C4DFF66',   // Import (Purple)
      '#00E67666',   // GenesisMint (Green)
      '#FF910066',   // URITokenBurn (Orange)
      '#84FFFF66',   // EscrowCreate (Cyan)
      '#1DE9B666',   // EscrowFinish (Turquoise)
      '#FF525266',   // EscrowCancel (Red)
      '#651FFF66',   // AccountSet (Deep Purple)
      '#FFB74D66',   // TrustSet (Light Orange)
      '#00C85366',   // OfferCreate (Green)
      '#FF408166',   // OfferCancel (Pink)
      '#00B0FF66',   // SignersListSet (Blue)
      '#AB47BC66',   // SetHook (Purple)
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  React.useEffect(() => {
    const fetchNFTData = async () => {
      try {
        const mockNFT = {
          id: id,
          name: "Xahau Digital Collectible #" + id,
          image: [
            '/images/anxels.png',
            '/images/azzerpardis.png',
            '/images/beast_belles.png',
            '/images/goat_club.png',
            '/images/hook_bots.png',
            '/images/living_legacy.png',
            '/images/monks.png',
            '/images/winnie.png',
            '/images/xahau_cows.png',
            '/images/xahau_eggs.png',
            '/images/xSoldiers.png'
          ][Math.floor(Math.random() * 11)],
          collection: "Xahau Legends Collection",
          creator: "CryptoMaster",
          owner: "rXXXXXXXXXXXXXXXXXXXXXXX",
          status: "listed",
          listPrice: "1000",
          floorPrice: "800",
          description: "A unique digital collectible on the Xahau network featuring stunning artwork and blockchain-verified authenticity.",
          properties: [
            { 
              type: "Background",
              value: "Cosmic",
              color: getRandomTransactionColor()
            },
            { 
              type: "Rarity",
              value: "Legendary",
              color: getRandomTransactionColor()
            },
            { 
              type: "Edition",
              value: "1/1",
              color: getRandomTransactionColor()
            },
            { 
              type: "Power Level",
              value: Math.floor(Math.random() * 9000 + 1000).toString(),
              color: getRandomTransactionColor()
            },
            { 
              type: "Element",
              value: ["Fire", "Water", "Earth", "Air", "Void"][Math.floor(Math.random() * 5)],
              color: getRandomTransactionColor()
            },
            { 
              type: "Class",
              value: ["Warrior", "Mage", "Rogue", "Paladin", "Druid"][Math.floor(Math.random() * 5)],
              color: getRandomTransactionColor()
            },
            { 
              type: "Weapon",
              value: ["Sword", "Staff", "Bow", "Axe", "Dagger"][Math.floor(Math.random() * 5)],
              color: getRandomTransactionColor()
            },
            { 
              type: "Armor",
              value: ["Plate", "Chain", "Leather", "Cloth", "Crystal"][Math.floor(Math.random() * 5)],
              color: getRandomTransactionColor()
            },
            { 
              type: "Special Ability",
              value: ["Time Warp", "Shadow Step", "Divine Shield", "Nature's Fury", "Frost Nova"][Math.floor(Math.random() * 5)],
              color: getRandomTransactionColor()
            }
          ]
        };

        setNft(mockNFT);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching NFT:", error);
        setLoading(false);
      }
    };

    fetchNFTData();
  }, [id]);

  if (loading) {
    return (
      <div className="nft-detail-loading">
        Loading
        <div className="loading-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  if (!nft) {
    return <div className="nft-detail-error">NFT not found</div>;
  }

  const isOwner = nft.owner === userWalletAddress;

  return (
    <div className="nft-detail-container">
      <div className="nft-detail-content">
        <div className="nft-detail-left">
          <div className="nft-image-container">
            <img src={nft.image} alt={nft.name} className="nft-detail-image" />
            {nft.status === 'listed' && (
              <div className="nft-badge listed">Listed</div>
            )}
          </div>
        </div>
        
        <div className="nft-detail-right">
          <h1>{nft.name}</h1>
          <div className="nft-detail-owner">
            Owned by <span className="wallet-address">{isOwner ? 'you' : nft.owner}</span>
          </div>
          
          <div className="nft-detail-price-section">
            {nft.status === 'listed' ? (
              <div className="current-price">
                <h3>Current Price</h3>
                <div className="balance-row">
                  <span className="amount">{nft.listPrice}</span>
                  <span className="currency">XAH</span>
                </div>
              </div>
            ) : (
              <div className="floor-price">
                <h3>Floor Price</h3>
                <div className="balance-row">
                  <span className="amount">{nft.floorPrice}</span>
                  <span className="currency">XAH</span>
                </div>
              </div>
            )}
          </div>

          <div className="nft-detail-description">
            <h3>Description</h3>
            <p>{nft.description}</p>
          </div>

          <div className="nft-detail-actions">
            {isOwner ? (
              nft.status === 'listed' ? (
                <button className="nft-button cancel-listing">Cancel Listing</button>
              ) : (
                <button className="nft-button sell">Sell</button>
              )
            ) : (
              nft.status === 'listed' ? (
                <button className="nft-button buy-now">Buy Now</button>
              ) : (
                <button className="nft-button make-offer">Make Offer</button>
              )
            )}
          </div>

          <div className="nft-detail-properties">
            <h3>Properties</h3>
            <div className="properties-grid">
              {nft.properties?.map((prop, index) => (
                <div 
                  key={index} 
                  className="property-item"
                  style={{ backgroundColor: prop.color }}
                >
                  <span className="property-type">{prop.type}</span>
                  <span className="property-value">{prop.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTDetail;