import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NFTCard.css';

const NFTCard = ({ nft, userWalletAddress }) => {
  const navigate = useNavigate();

  const isOwner = nft.owner === userWalletAddress;

  const handleImageClick = () => {
    navigate(`/nft/${nft.id}`);
  };

  const handleAction = () => {
    // Add action handling here
  };

  const renderButton = () => {
    if (isOwner) {
      if (nft.status === 'listed') {
        return (
          <button className="nft-button cancel-listing" onClick={handleAction}>
            Cancel Listing
          </button>
        );
      }
      return (
        <button className="nft-button sell" onClick={handleAction}>
          Sell
        </button>
      );
    } else {
      if (nft.status === 'listed') {
        return (
          <button className="nft-button buy-now" onClick={handleAction}>
            Buy Now
          </button>
        );
      }
      return (
        <button className="nft-button make-offer" onClick={handleAction}>
          Make Offer
        </button>
      );
    }
  };

  return (
    <div className="nft-item">
      <div className="nft-image-container">
        <img 
          src={nft.image} 
          alt={nft.name} 
          className="nft-image"
          onClick={handleImageClick}
        />
        {nft.status === 'listed' && (
          <div className="nft-badge listed">Listed</div>
        )}
        {nft.status === 'has_offers' && (
          <div className="nft-badge has-offers">Has Offers</div>
        )}
      </div>
      <div className="nft-details">
        <p>By {nft.collection}</p>
        <p className="price">{nft.listPrice ? `${nft.listPrice} XAH` : `${nft.floorPrice} XAH`}</p>
        {renderButton()}
      </div>
    </div>
  );
};

export default NFTCard; 