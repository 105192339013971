/**
 * Events Page Component - Coming Soon
 */

import React from 'react';
import './Events.css';

const Events = () => {
  return (
    <div className="events-coming-soon">
      <h1>Events</h1>
      <div className="construction-message">
        <h2>Coming Soon</h2>
        <p>Our events page is under construction. Check back later for upcoming Xahau ecosystem events!</p>
      </div>
    </div>
  );
};

export default Events;