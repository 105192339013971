import React from 'react';
import './GrantCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faVoteYea, faSpinner } from '@fortawesome/free-solid-svg-icons';

const GrantCard = ({ grant, onClick }) => {
  const getStatusIcon = (status) => {
    switch(status) {
      case 'Open for Voting': return faVoteYea;
      case 'In Review': return faClock;
      case 'Approved': return faCheckCircle;
      case 'In Progress': return faSpinner;
      default: return faVoteYea;
    }
  };

  return (
    <div className="grant-card main-balance" onClick={onClick}>
      <div className="grant-card-container">
        <div className="grant-card-info">
          <h3>{grant.title}</h3>
          <div className="grant-row">
            <span className="grant-amount">{grant.amount}</span>
            <span className="currency">XAH</span>
          </div>
          <div className="grant-row">
            <span className="grant-timeline">{grant.timeline}</span>
          </div>
        </div>
        <div className="grant-card-category">
          <h3>Category</h3>
          <div className="grant-row">
            <span className={`grant-category ${grant.category}`}>
              {grant.category}
            </span>
          </div>
          <div className="grant-row">
            <span className={`grant-status ${grant.status.toLowerCase().replace(/\s+/g, '-')}`}>
              <FontAwesomeIcon icon={getStatusIcon(grant.status)} className="status-icon" />
              {grant.status}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrantCard; 